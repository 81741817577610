import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section-header',{attrs:{"title":"Top 10 Favourites"}}),_c(VRow,[_c(VCol),_c(VCol,_vm._l((_vm.favSongs),function(song){return _c(VListItem,{key:song.title,on:{"click":function($event){$event.stopPropagation();return _vm.playSong(song)}}},[_c(VListItemAvatar,[_c(VBtn,{attrs:{"fab":"","dark":"","x-small":"","color":"primary darken-2"}},[_c(VIcon,[_vm._v("mdi-play")])],1)],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(song.title))])],1)],1)}),1),_c(VCol)],1),_c(VDialog,{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? 840 : 420,"persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VToolbar,{attrs:{"dark":""}},[_c('span',[_vm._v(_vm._s(_vm.currentSong.title))]),_c('span',{staticClass:"subtitle-2 grey--text pl-4"},[_vm._v("℗ "+_vm._s(_vm.currentSong.channel))]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1),_c(VCardText,{staticClass:"ma-0 pa-0 text-center"},[_c('iframe',{staticStyle:{"border":"none"},attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? 800 : 400,"height":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 250,"src":`https://www.youtube.com/embed/${_vm.currentSong.link}?autoplay=1`,"allow":"fullscreen;"}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }